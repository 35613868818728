import { inject, Injectable, NgModule } from '@angular/core';
import { RouterModule, RouterStateSnapshot, Routes, TitleStrategy } from '@angular/router';
import { loginGuard } from '@shared/guards/login.guard';
import { DashboardLayoutComponent } from '@shared/components/layouts/dashboard-layout/dashboard-layout.component';
import { PermissionsGuard } from '@shared/guards/permissions.guard';
import { Title } from '@angular/platform-browser';
import { LocalPermissions, GlobalPermissions } from '@root/models/global-enum';

const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [loginGuard, PermissionsGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./shared/components/global-dashboard/global-dashboard.component').then(
            mod => mod.GlobalDashboardComponent,
          ),
        data: {
          atLeastOneGlobal: [GlobalPermissions.GLOBAL_ADMIN, GlobalPermissions.GLOBAL_VIEWER],
          atLeastOneLocal: [],
        },
        canActivate: [loginGuard, PermissionsGuard],
        title: 'Global Dashboard',
      },
      {
        path: 'global/dashboard',
        loadComponent: () =>
          import('./shared/components/global-dashboard/global-dashboard.component').then(
            mod => mod.GlobalDashboardComponent,
          ),
        data: {
          atLeastOneGlobal: [GlobalPermissions.GLOBAL_ADMIN, GlobalPermissions.GLOBAL_VIEWER],
          atLeastOneLocal: [],
        },
        canActivate: [loginGuard, PermissionsGuard],
        title: 'Global Dashboard',
      },
      {
        path: 'global/no-locations',
        loadComponent: () =>
          import('./shared/components/no-locations/no-locations.component').then(
            mod => mod.NoLocationsComponent,
          ),
        data: {
          atLeastOneGlobal: [GlobalPermissions.GLOBAL_ADMIN, GlobalPermissions.GLOBAL_VIEWER],
          atLeastOneLocal: [],
        },
        canActivate: [loginGuard, PermissionsGuard],
        title: 'No Locations',
      },
      {
        path: 'global/users-and-roles/users',
        loadComponent: () =>
          import('./views/usersAndRoles/users/users.component').then(mod => mod.UsersComponent),
        data: {
          atLeastOneGlobal: [GlobalPermissions.GLOBAL_ADMIN, GlobalPermissions.GLOBAL_VIEWER],
          atLeastOneLocal: [],
        },
        canActivate: [loginGuard, PermissionsGuard],
        title: 'Global Users',
      },
      {
        path: 'global/users-and-roles/roles',
        loadComponent: () =>
          import('./views/usersAndRoles/roles/roles.component').then(mod => mod.RolesComponent),
        data: {
          atLeastOneGlobal: [GlobalPermissions.GLOBAL_ADMIN, GlobalPermissions.GLOBAL_VIEWER],
          atLeastOneLocal: [],
        },
        canActivate: [loginGuard, PermissionsGuard],
        title: 'Global Roles',
      },
      {
        path: 'locations/:locationId/documents',
        loadComponent: () =>
          import('./views/local/local-documents/local-documents.component').then(
            mod => mod.LocalDocumentsComponent,
          ),
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [LocalPermissions.LOCAL_ADMIN, LocalPermissions.LOCAL_VIEWER],
        },
        canActivate: [loginGuard, PermissionsGuard],
        title: 'Documents',
      },
      {
        path: 'locations/:locationId/users',
        loadComponent: () =>
          import('./views/local/local-users/local-users.component').then(
            mod => mod.LocalUsersComponent,
          ),
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [LocalPermissions.LOCAL_ADMIN, LocalPermissions.LOCAL_VIEWER],
        },
        canActivate: [loginGuard, PermissionsGuard],
        title: 'Users',
      },
      {
        path: 'locations/:locationId/roles',
        loadComponent: () =>
          import('./views/local/local-roles/local-roles.component').then(
            mod => mod.LocalRolesComponent,
          ),
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [LocalPermissions.LOCAL_ADMIN, LocalPermissions.LOCAL_VIEWER],
        },
        canActivate: [loginGuard, PermissionsGuard],
        title: 'Roles',
      },
      {
        path: 'locations/:locationId/templates',
        loadComponent: () =>
          import('./views/local/local-templates/local-templates.component').then(
            mod => mod.LocalTemplatesComponent,
          ),
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [LocalPermissions.LOCAL_ADMIN, LocalPermissions.LOCAL_VIEWER],
        },
        canActivate: [loginGuard, PermissionsGuard],
        title: 'Task Templates',
      },
      {
        path: 'locations/:locationId/my-tasks',
        loadComponent: () =>
          import('@views/local/local-my-tasks/my-tasks.component').then(
            mod => mod.MyTasksComponent,
          ),
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [],
        },
        canActivate: [loginGuard, PermissionsGuard],
        title: 'My Tasks',
      },
      {
        path: 'locations/:locationId/tasks/closed',
        loadComponent: () =>
          import('./views/local/local-closed-tasks/local-closed-tasks.component').then(
            mod => mod.LocalClosedTasksComponent,
          ),
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [
            LocalPermissions.LOCAL_ADMIN,
            LocalPermissions.LOCAL_VIEWER,
            LocalPermissions.ALLOW_TASK_SCORING,
            LocalPermissions.REASSIGN_MY_TASKS_AND_TASK_SETS_TO_OTHERS,
            LocalPermissions.COMPLETE_OTHERS_TASKS,
            LocalPermissions.DELETE_SPAWNED_TASKS,
            LocalPermissions.SPAWN_FROM_EVENT_WIZARD,
            LocalPermissions.ASSIGN_TASK_AND_SETS_TO_OTHERS,
          ],
        },
        canActivate: [loginGuard, PermissionsGuard],
        title: 'Closed Tasks',
      },
      {
        path: 'locations/:locationId/local-dashboard',
        loadComponent: () =>
          import('./views/local/local-dashboard/local-dashboard.component').then(
            mod => mod.LocalDashboardComponent,
          ),
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [],
        },
        canActivate: [loginGuard, PermissionsGuard],
        title: 'Dashboard',
      },
      {
        path: 'locations/:locationId/event-wizard',
        loadComponent: () =>
          import('./views/local/local-event-wizard/local-event-wizard.component').then(
            mod => mod.LocalEventWizardComponent,
          ),
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [],
        },
        canActivate: [loginGuard, PermissionsGuard],
        title: 'Event Wizard',
      },
      {
        path: 'locations-and-categories',
        loadComponent: () =>
          import('./views/locations-and-categories/locations-and-categories.component').then(
            mod => mod.LocationsAndCategoriesComponent,
          ),
        title: 'Locations and Categories',
        data: {
          atLeastOneGlobal: [GlobalPermissions.GLOBAL_ADMIN, GlobalPermissions.GLOBAL_VIEWER],
          atLeastOneLocal: [],
        },
        canActivate: [loginGuard, PermissionsGuard],
      },
      {
        path: 'global/references',
        loadComponent: () =>
          import('./views/global-references/global-references.component').then(
            mod => mod.GlobalReferencesComponent,
          ),
        data: {
          atLeastOneGlobal: [GlobalPermissions.GLOBAL_ADMIN, GlobalPermissions.GLOBAL_VIEWER],
          atLeastOneLocal: [],
        },
        canActivate: [loginGuard, PermissionsGuard],
        title: 'Global References',
      },
      {
        path: 'global/templates',
        loadComponent: () =>
          import('./views/global-templates/global-templates.component').then(
            mod => mod.GlobalTemplatesComponent,
          ),
        title: 'Global Templates',
        data: {
          atLeastOneGlobal: [GlobalPermissions.GLOBAL_ADMIN, GlobalPermissions.GLOBAL_VIEWER],
          atLeastOneLocal: [],
        },
        canActivate: [loginGuard, PermissionsGuard],
      },
      {
        path: 'global/reports',
        loadComponent: () =>
          import('./views/reports/reports.component').then(mod => mod.ReportsComponent),
        title: 'Global Reports',
        data: {
          atLeastOneGlobal: [
            GlobalPermissions.GLOBAL_ADMIN,
            GlobalPermissions.GLOBAL_VIEWER,
            GlobalPermissions.REPORT_VIEWER,
            GlobalPermissions.REPORT_ADMIN,
          ],
          atLeastOneLocal: [],
        },
        canActivate: [loginGuard, PermissionsGuard],
      },
      {
        path: 'global/reports/:reportId',
        loadComponent: () =>
          import('./shared/components/report/report.component').then(mod => mod.ReportComponent),
        title: 'Report Editor',
        data: {
          atLeastOneGlobal: [
            GlobalPermissions.GLOBAL_ADMIN,
            GlobalPermissions.GLOBAL_VIEWER,
            GlobalPermissions.REPORT_VIEWER,
            GlobalPermissions.REPORT_ADMIN,
          ],
          atLeastOneLocal: [],
        },
        canActivate: [loginGuard, PermissionsGuard],
      },
      {
        path: 'global/reports/:reportId/view',
        loadComponent: () =>
          import('./shared/components/view-report/view-report.component').then(
            mod => mod.ViewReportComponent,
          ),
        title: 'Report Viewer',
        data: {
          atLeastOneGlobal: [
            GlobalPermissions.GLOBAL_ADMIN,
            GlobalPermissions.GLOBAL_VIEWER,
            GlobalPermissions.REPORT_VIEWER,
            GlobalPermissions.REPORT_ADMIN,
          ],
          atLeastOneLocal: [],
        },
        canActivate: [loginGuard, PermissionsGuard],
      },
      {
        path: 'global/reports/:reportId/sections/:sectionId/rows/:rowId',
        loadComponent: () =>
          import(
            './shared/components/view-table-report-task/view-table-report-task.component'
          ).then(mod => mod.ViewTableReportTaskComponent),
        title: 'Report Task Viewer',
        data: {
          atLeastOneGlobal: [
            GlobalPermissions.GLOBAL_ADMIN,
            GlobalPermissions.GLOBAL_VIEWER,
            GlobalPermissions.REPORT_VIEWER,
            GlobalPermissions.REPORT_ADMIN,
          ],
          atLeastOneLocal: [],
        },
        canActivate: [loginGuard, PermissionsGuard],
      },
      {
        path: 'locations/:locationId/templates/tasks/:taskId',
        loadComponent: () => import('./views/task/task.component').then(mod => mod.TaskComponent),
        title: 'Local Task',
        canActivate: [loginGuard, PermissionsGuard],
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [LocalPermissions.LOCAL_ADMIN, LocalPermissions.LOCAL_VIEWER],
        },
      },
      {
        path: 'locations/:locationId/tasks/:taskId',
        loadComponent: () => import('./views/task/task.component').then(mod => mod.TaskComponent),
        title: 'Local Task',
        canActivate: [loginGuard, PermissionsGuard],
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [],
        },
      },
      {
        path: 'locations/:locationId/task-sets/:taskSetId',
        loadComponent: () =>
          import('@views/task-set/task-set.component').then(mod => mod.TaskSetComponent),
        title: 'Local Task Set',
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [LocalPermissions.LOCAL_ADMIN, LocalPermissions.LOCAL_VIEWER],
        },
      },
      {
        path: 'global/templates/tasks/:taskId',
        loadComponent: () => import('./views/task/task.component').then(mod => mod.TaskComponent),
        title: 'Global Task',
        data: {
          atLeastOneGlobal: [GlobalPermissions.GLOBAL_ADMIN, GlobalPermissions.GLOBAL_VIEWER],
          atLeastOneLocal: [],
        },
        canActivate: [loginGuard, PermissionsGuard],
      },
      {
        path: 'locations/:locationId/templates/task-sets/:taskSetId',
        loadComponent: () =>
          import('@views/task-set/task-set.component').then(mod => mod.TaskSetComponent),
        title: 'Local Task Set',
        canActivate: [loginGuard, PermissionsGuard],
        data: {
          atLeastOneGlobal: [],
          atLeastOneLocal: [LocalPermissions.LOCAL_ADMIN, LocalPermissions.LOCAL_VIEWER],
        },
      },
      {
        path: 'global/templates/task-sets/:taskSetId',
        loadComponent: () =>
          import('@views/task-set/task-set.component').then(mod => mod.TaskSetComponent),
        title: 'Global Task Set',
        data: {
          atLeastOneGlobal: [GlobalPermissions.GLOBAL_ADMIN, GlobalPermissions.GLOBAL_VIEWER],
          atLeastOneLocal: [],
        },
        canActivate: [loginGuard, PermissionsGuard],
      },
    ],
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./views/auth/login/login.component').then(mod => mod.LoginComponent),
    canActivate: [loginGuard],
    title: 'Login',
  },
  {
    path: 'reset-password/:token',
    loadComponent: () =>
      import('./views/auth/reset-password/reset-password.component').then(
        mod => mod.ResetPasswordComponent,
      ),
    title: 'Reset Password',
  },
  {
    path: 'unauthorized',
    loadComponent: () =>
      import('./views/unauthorized/unauthorized.component').then(mod => mod.UnauthorizedComponent),
  },
  {
    path: 'no-permissions',
    loadComponent: () =>
      import('./shared/components/no-permissions/no-permissions.component').then(
        mod => mod.NoPermissionsComponent,
      ),
  },
  {
    path: 'locations/:locationId/taskboard',
    loadComponent: () =>
      import('./shared/components/taskboard/taskboard.component').then(
        mod => mod.TaskboardComponent,
      ),
    data: {
      atLeastOneGlobal: [],
      atLeastOneLocal: [],
      checkTaskboardPermissions: true,
    },
    canActivate: [loginGuard, PermissionsGuard],
  },
  {
    path: '!/login',
    redirectTo: 'login',
  },
  {
    path: '**',
    loadComponent: () =>
      import('./views/page-not-found/page-not-found.component').then(
        mod => mod.PageNotFoundComponent,
      ),
  },
];

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
  private readonly title = inject(Title);

  override updateTitle(routerState: RouterStateSnapshot) {
    /* eslint-disable @typescript-eslint/typedef */
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`Terlumina - ${title}`);
    }
  }
}
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [{ provide: TitleStrategy, useClass: TemplatePageTitleStrategy }],
})
export class AppRoutingModule {}
