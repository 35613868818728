import { Component, effect, inject, isDevMode } from '@angular/core';
import { CustomerInfo, UserInfo } from '@root/models/user.model';
import { UserService } from '@root/services/user-service/user.service';
import { GeneralStore } from '@root/store/general.store';
import Intercom, { update } from '@intercom/messenger-js-sdk';
import LogRocket from 'logrocket';
import { environment } from '@root/environments/environment';
import { ActivationEnd, Router } from '@angular/router';
import { LocationsStore } from '@root/store/location.store';
import { filter, map } from 'rxjs';

if (environment.shouldEnableIntercom) {
  Intercom({
    app_id: 'gg1xcirb',
  });
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private readonly router = inject(Router);
  readonly generalStore = inject(GeneralStore);
  readonly locationsStore = inject(LocationsStore);
  readonly userService: UserService = inject(UserService);
  isLoaded: boolean = false;
  loading: boolean = false;
  constructor() {
    if (!isDevMode()) {
      LogRocket.init('uzclgt/katanaprod');
    } else {
      LogRocket.init('uzclgt/development-kb3lj');
    }

    effect(() => {
      this.isLoaded = this.generalStore.isLoaded();
    });

    effect(() => {
      this.loading = this.generalStore.loading();
    });

    effect(() => {
      if (environment.shouldEnableIntercom) {
        const tempUser: UserInfo = this.generalStore.userInfo();
        try {
          if (tempUser && tempUser.userID) {
            update({
              email: tempUser.userEmail,
              name: tempUser.userFullName,
              created_at: new Date(tempUser.created_at).getTime(),
              user_id: tempUser.userID.toString(),
              user_hash: tempUser.userHash,
            });
          } else {
            update({
              email: null,
              name: null,
              created_at: null,
              user_id: null,
            });
          }
        } catch (e) {
          console.error('Error updating user info in Intercom', e);
        }
      }
    });

    this.userService.getCustomerInfo().subscribe((res: CustomerInfo) => {
      this.generalStore.setCustomerInfo(res);
    });
    this.router.events
      .pipe(
        filter(e => e instanceof ActivationEnd && Object.keys(e.snapshot.params).length > 0),
        map(e => (e instanceof ActivationEnd ? e.snapshot.params : {})),
      )
      .subscribe(params => {
        const newLocationId: number = +params['locationId'];
        this.locationsStore.setCurrentLocationId(+newLocationId);
      });
  }
}
