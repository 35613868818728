import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingModalDialogComponent } from '../../dialogs/loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss',
})
export class LoadingComponent implements OnInit, OnDestroy {
  protected readonly dialog = inject(MatDialog);

  @Input() loadingText: string = 'Loading...';
  @Input() spinnerDiameter: number = 50;

  private dialogRef: MatDialogRef<LoadingModalDialogComponent>;

  ngOnInit(): void {
    this.dialogRef = this.dialog.open(LoadingModalDialogComponent, {
      data: { loadingText: this.loadingText, spinnerDiameter: this.spinnerDiameter },
    });
  }

  ngOnDestroy(): void {
    this.dialogRef.close();
    this.dialogRef = null;
  }
}
