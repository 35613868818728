import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreService } from '../services/local-store.service';
import { inject, Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  private readonly authService = inject(LocalStoreService);
  private apiUrl = environment.apiUrl;
  private unauthenticatedRoutes: string[] = ['customer'];
  intercept(
    /* eslint-disable @typescript-eslint/no-explicit-any */
    request: HttpRequest<any>,
    /* eslint-disable @typescript-eslint/no-explicit-any */
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let addAuthHeader: boolean = true;
    this.unauthenticatedRoutes.forEach((route: string) => {
      const testString: string = this.apiUrl + route;
      if (request.url === testString) {
        addAuthHeader = false;
      }
    });
    if (addAuthHeader) {
      const token: string = this.authService.getItem('JWT_TOKEN');
      if (token && !request.url.includes('.s3.')) {
        request = request.clone({
          setHeaders: { Authorization: `Bearer ${token}` },
        });
      }
    }
    return next.handle(request);
  }
}
